/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, CloudUploadIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  InformationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

const applications = [
  {
    id: 1,
    name: 'Stamdata Rapportage 2021 Q2 Automotive',
    date: '2020-01-07',
    dateFull: '14 Juni, 2020',
    stage: 'Wachten op aanlevering',
    href: '#',
  },
  {
    id: 2,
    name: 'Financial data Rapportage 2020 Q1 Automotive',
    date: '2020-01-07',
    dateFull: '14 April, 2021',
    stage: 'Geen data aangeleverd - actie ondernemen!',
    href: '#',
  },
  {
    id: 3,
    name: 'Omzetgegevens 2020 Q4',
    date: '2020-01-07',
    dateFull: '14 Januari, 2021',
    stage: 'Uw data is gecontroleerd en verwerkt',
    href: '#',
  },
];

export const DataUploadPage = () => {
  const { t } = useTranslation();
  return (
    <div className='py-6 space-y-4'>
      <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8'>
        {/* <Breadcrumbs /> */}
        <h1 className='flex items-center justify-start space-x-2 text-3xl font-bold text-gray-900 uppercase'>
          <CloudUploadIcon className='w-8 h-8' />
          <span className='tracking-tight'>{t('Mijn dataleveringen')}</span>
          <span className='flex-grow-0 w-20 h-1 bg-black'></span>
        </h1>
      </div>

      <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 md:px-8'>
        <div className='overflow-hidden bg-white shadow sm:rounded-md'>
          <ul className='divide-y divide-gray-200'>
            {applications.map((application) => (
              <li key={application.name}>
                <a href={application.href} className='block hover:bg-gray-50'>
                  <div className='flex items-center px-4 py-4 sm:px-6'>
                    <div className='flex items-center flex-1 min-w-0'>
                      <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4'>
                        <div>
                          <p className='text-sm font-medium text-yellow-600 truncate'>{application.name}</p>
                          <p className='flex items-center mt-2 text-sm text-gray-500'>
                            <CalendarIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                            <span className='truncate'>
                              vóór <time dateTime={application.date}>{application.dateFull}</time>
                            </span>
                          </p>
                        </div>
                        <div className='hidden md:block'>
                          <div>
                            <p className='text-sm text-gray-900'>
                              Laatste wijziging:&nbsp;
                              {application.id === 1 && <span>(geen)</span>}
                              {application.id === 2 && <span>(geen)</span>}
                              {application.id === 3 && <span>24 Januari, 2021</span>}
                            </p>
                            {application.id === 1 && (
                              <p className='flex items-center mt-2 text-sm text-gray-500'>
                                <InformationCircleIcon
                                  className='flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400'
                                  aria-hidden='true'
                                />
                                {application.stage}
                              </p>
                            )}
                            {application.id === 2 && (
                              <p className='flex items-center mt-2 text-sm font-semibold text-gray-500'>
                                <ExclamationIcon
                                  className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
                                  aria-hidden='true'
                                />
                                {application.stage}
                              </p>
                            )}
                            {application.id === 3 && (
                              <p className='flex items-center mt-2 text-sm text-gray-500'>
                                <CheckCircleIcon
                                  className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                                  aria-hidden='true'
                                />
                                {application.stage}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

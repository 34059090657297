import classNames from 'classnames';

interface CardProps {
  className?: string;
}

export const Card: React.FC<CardProps> = ({ className, children }) => {
  const containerClass = classNames('overflow-hidden bg-white shadow sm:rounded-lg', className);
  return <div className={containerClass}>{children}</div>;
};

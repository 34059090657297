import { useTranslation } from 'react-i18next';

import { ContactForm } from '../faq/ContactForm';
import { FaqOverview } from '../faq/FaqOverview';
import { Card } from '../generic/Card';

export const FAQPage = () => {
  const { t } = useTranslation();
  return (
    <div className='py-6 space-y-4'>
      <div className='px-4 mx-auto max-w-7xl sm:px-6 md:px-8'>
        <Card className='mb-4'>
          <div className='px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-20 lg:px-8'>
            <div className='lg:grid lg:grid-cols-3 lg:gap-8'>
              <div>
                <h2 className='text-3xl font-semibold text-gray-900'>{t('Veelgestelde vragen')}</h2>
                <p className='mt-4 text-lg text-gray-500'>
                  {t('Staat het antwoord op uw vraag er niet bij? neem dan contact op met ons')}
                  <a href='#' className='font-medium text-yellow-600 hover:text-yellow-500'>
                    &nbsp;support team.
                  </a>
                </p>
              </div>
              <FaqOverview />
            </div>
          </div>
        </Card>
        <ContactForm />
      </div>
    </div>
  );
};

import { processSilentRenew } from 'redux-oidc';

/**
 * This function is responsible for creating an iframe @see processSilentRenew that
 * will automatically fetch new tokens for the users when they are about to run out.
 * The user sees nothing, but we need to render something to use this as a component.
 */
const SilentRenewComponent = () => {
  processSilentRenew();
  return <div></div>;
};

export default SilentRenewComponent;

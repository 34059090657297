import { ChartSquareBarIcon, CloudUploadIcon, HomeIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';

interface MenuItem {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  link: string;
}

export const menuItems: MenuItem[] = [
  {
    icon: HomeIcon,
    title: 'Dashboard',
    link: '/app/dashboard',
  },
  {
    icon: CloudUploadIcon,
    title: 'Datalevering',
    link: '/app/data-delivery',
  },
  {
    icon: ChartSquareBarIcon,
    title: 'Rapportages',
    link: '/app/reports',
  },
  {
    icon: QuestionMarkCircleIcon,
    title: 'Veelgestelde vragen',
    link: '/app/faq',
  },
];

export type MenuType = 'desktop' | 'mobile';

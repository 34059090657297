import userManager from './userManager';

/** We do not use this yet. TODO: Signout. */
const LogoutPage = () => {
  userManager.removeUser().then(() => userManager.signoutRedirect());
  // userManager.signoutRedirect().then(() => {
  //     return null;
  // });
  return <></>;
};

export default LogoutPage;

import { HomeIcon } from '@heroicons/react/outline';
import { models, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Root Component to demonstrate usage of wrapper component
export const ReportPage = () => {
  // PowerBI Report object (to be received via callback)
  const { t } = useTranslation();
  // API end-point url to get embed config for a sample report
  const sampleReportUrl = 'https://playgroundbe-bck-1.azurewebsites.net/Reports/SampleReport';

  const [isLoading, setIsLoading] = useState(false);

  // Report config useState hook
  // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
  const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  });

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered');

        // Update display message
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<unknown>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding

  useEffect(() => {
    if (!isLoading && sampleReportConfig.accessToken === undefined) {
      const mockSignIn = async () => {
        // Fetch sample report's embed config
        const reportConfigResponse = await fetch(sampleReportUrl);

        if (!reportConfigResponse.ok) {
          console.error(
            `Failed to fetch config for report. Status: ${reportConfigResponse.status} ${reportConfigResponse.statusText}`
          );
          return;
        }

        const reportConfig = await reportConfigResponse.json();

        // Update display message

        // Set the fetched embedUrl and embedToken in the report config
        setReportConfig({
          ...sampleReportConfig,
          embedUrl: reportConfig.EmbedUrl,
          accessToken: reportConfig.EmbedToken.Token,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
            },
          },
        });
        setIsLoading(false);
      };
      mockSignIn();
      setIsLoading(true);
    }
  }, [isLoading, sampleReportConfig]);

  const pages = [
    { name: t('Mijn rapportages'), href: '/app/reports', current: false },
    { name: t('Rapportage Omzet Q4 Automotive'), href: '/app/report', current: true },
  ];

  return (
    <div className='min-h-screen py-6 space-y-4'>
      <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8'>
        <nav className='flex' aria-label='Breadcrumb'>
          <ol className='flex px-6 space-x-4 bg-white rounded-md shadow'>
            <li className='flex'>
              <div className='flex items-center'>
                <Link to='/app/dashboard' className='text-gray-400 hover:text-gray-500'>
                  <HomeIcon className='flex-shrink-0 w-5 h-5' aria-hidden='true' />
                  <span className='sr-only'>Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name} className='flex'>
                <div className='flex items-center'>
                  <svg
                    className='flex-shrink-0 w-6 h-full text-gray-200'
                    viewBox='0 0 24 44'
                    preserveAspectRatio='none'
                    fill='currentColor'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                  >
                    <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                  </svg>
                  <Link
                    to={page.href}
                    className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <h1 className='flex items-center justify-start text-3xl font-bold text-gray-900 uppercase '>
          <span className='tracking-tight'>{t('Rapportage Omzet Q4 Automotive')}</span>
          <span className='flex-grow-0 w-20 h-1 ml-2 bg-black'></span>
        </h1>
      </div>
      <div className='px-2 -mx-4 '>
        <PowerBIEmbed
          embedConfig={sampleReportConfig}
          eventHandlers={eventHandlersMap}
          cssClassName={'report-style-class'}
        />
      </div>
    </div>
  );
};

import { ArrowNarrowRightIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Card } from '../generic/Card';

const reports = [
  {
    title: 'Omzetcijfers Q1 2021',
    description: 'Omzetcijfers afdeling Automotive and Naval services',
    lastChanged: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay() + 1, 8, 45, 0),
    isNew: true,
  },
  {
    title: 'Omzetcijfers Q4 2020',
    description: 'Omzetcijfers afdeling Automotive and Naval services',
    lastChanged: new Date(2021, 0, 12, 9, 45, 0),
    isNew: false,
  },
  {
    title: 'Omzetcijfers Q4 2020',
    description: 'Omzetcijfers afdeling Professional services',
    lastChanged: new Date(2021, 0, 12, 8, 30, 0),
    isNew: false,
  },
  {
    title: 'Omzetcijfers Q3 2020',
    description: 'Omzetcijfers afdeling Automotive and Naval services',
    lastChanged: new Date(2020, 9, 12, 12, 30, 0),
    isNew: false,
  },
  {
    title: 'Omzetcijfers Q3 2020',
    description: 'Omzetcijfers afdeling Professional services',
    lastChanged: new Date(2020, 9, 12, 8, 30, 0),
    isNew: false,
  },
  {
    title: 'Omzetcijfers Q2 2020',
    description: 'Omzetcijfers afdeling Automotive and Naval services',
    lastChanged: new Date(2020, 6, 12, 8, 30, 0),
    isNew: false,
  },
  {
    title: 'Omzetcijfers Q2 2020',
    description: 'Omzetcijfers afdeling Professional services',
    lastChanged: new Date(2020, 6, 12, 8, 30, 0),
    isNew: false,
  },
];

interface ReportOverviewProps {
  maxShown?: number;
}

export const ReportOverviewCard = ({ maxShown }: ReportOverviewProps) => {
  const { t } = useTranslation();
  let opacity = 110;
  const reportsToShow = maxShown === undefined ? reports : reports.slice(0, maxShown);
  return (
    <Card>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='overflow-hidden border-b border-gray-200 shadow sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope='col' className='relative px-6 py-3'>
                      <span className='sr-only'>Is nieuw</span>
                    </th>
                    <th
                      scope='col'
                      className='py-3 pl-2 pr-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                    >
                      {t('Naam')}
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                    >
                      {t('Omschrijving')}
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                    >
                      {t('Laatste wijziging')}
                    </th>
                    <th scope='col' className='relative px-6 py-3'>
                      <span className='sr-only'>Bekijk</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {reportsToShow.map((report) => {
                    opacity = maxShown !== undefined ? opacity - 10 : opacity;
                    return (
                      <tr key={report.title} className={`opacity-${opacity} hover:opacity-100`}>
                        <td className='py-4 pl-2 pr-0 text-sm font-medium text-gray-900 whitespace-nowrap'>
                          {report.isNew && (
                            <span className='inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-yellow-100 uppercase bg-yellow-600 rounded font-bolder'>
                              {t('Nieuw!')}
                            </span>
                          )}
                        </td>
                        <td className='py-4 pl-2 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'>
                          {report.title}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>{report.description}</td>
                        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                          {report.lastChanged.toLocaleString('nl-NL')}
                        </td>
                        <td className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                          <Link
                            to='/app/reports/a4a6d35d-7a89-43cc-8856-81897dda3dfc'
                            className='flex items-center justify-end text-yellow-600 hover:text-yellow-900'
                          >
                            <span>Bekijk rapportage</span>
                            <ArrowNarrowRightIcon className='w-5 h-5 ml-4' />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                  {maxShown !== undefined && (
                    <tr>
                      <td></td>
                      <td colSpan={4} className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                        <Link
                          to='/app/reports'
                          className='flex items-center justify-end text-yellow-700 hover:text-yellow-900'
                        >
                          <span>Bekijk alle rapportages</span>
                          <ArrowRightIcon className='w-5 h-5 ml-4' />
                        </Link>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

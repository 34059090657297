/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { Card } from '../generic/Card';

export const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <div className='relative bg-white'>
        <div className='absolute inset-0'>
          <div className='absolute inset-y-0 left-0 w-1/2 bg-gray-50' />
        </div>
        <div className='relative mx-auto max-w-7xl lg:grid lg:grid-cols-5'>
          <div className='px-4 py-16 bg-gray-50 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12'>
            <div className='max-w-lg mx-auto'>
              <h2 className='text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl'>
                {t('Neem contact op')}
              </h2>
              <p className='mt-3 text-lg leading-6 text-gray-500'>
                {t('Onze supportafdeling zit klaar om uw vragen te beantwoorden!')}
              </p>
              <dl className='mt-8 text-base text-gray-500'>
                <div>
                  <dt className='sr-only'>{t('Adres')}</dt>
                  <dd>
                    <p> {t('The Reporting Agency B.V.')}</p>
                    <p> {t('Gustav Mahlerlaan 1A')}</p>
                    <p> {t('1082 ME Amsterdam')}</p>
                  </dd>
                </div>
                <div className='mt-6'>
                  <dt className='sr-only'>{t('Telefoon')}</dt>
                  <dd className='flex'>
                    <PhoneIcon className='flex-shrink-0 w-6 h-6 text-gray-400' aria-hidden='true' />
                    <span className='ml-3'>+31 88 123 4567</span>
                  </dd>
                </div>
                <div className='mt-3'>
                  <dt className='sr-only'>{t('E-mail')}</dt>
                  <dd className='flex'>
                    <MailIcon className='flex-shrink-0 w-6 h-6 text-gray-400' aria-hidden='true' />
                    <span className='ml-3'>support@thereportingagency.nl</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className='px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12'>
            <div className='max-w-lg mx-auto lg:max-w-none'>
              <form action='#' method='POST' className='grid grid-cols-1 gap-y-6'>
                <div>
                  <label htmlFor='full_name' className='sr-only'>
                    {t('Uw naam')}
                  </label>
                  <input
                    type='text'
                    name='full_name'
                    id='full_name'
                    autoComplete='name'
                    className='block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    placeholder={t('Uw naam')}
                  />
                </div>
                <div>
                  <label htmlFor='email' className='sr-only'>
                    {t('E-mail')}
                  </label>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    className='block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    placeholder={t('E-mail')}
                  />
                </div>
                <div>
                  <label htmlFor='phone' className='sr-only'>
                    {t('Telefoon')}
                  </label>
                  <input
                    type='text'
                    name='phone'
                    id='phone'
                    autoComplete='tel'
                    className='block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    placeholder={t('Telefoon')}
                  />
                </div>
                <div>
                  <label htmlFor='message' className='sr-only'>
                    {t('Vraag / opmerking')}
                  </label>
                  <textarea
                    id='message'
                    name='message'
                    rows={4}
                    className='block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                    placeholder='Message'
                    defaultValue={''}
                  />
                </div>
                <div>
                  <button
                    type='submit'
                    className='inline-flex justify-center px-6 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    {t('Verzenden')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

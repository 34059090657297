/* This example requires Tailwind CSS v2.0+ */
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Banner() {
  const [visible, setIsVisible] = useState(true);
  return !visible ? null : (
    <div className='bg-yellow-600'>
      <div className='px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8'>
        <div className='flex flex-wrap items-center justify-between'>
          <div className='flex items-center flex-1 w-0'>
            <span className='flex p-2 bg-yellow-800 rounded-lg'>
              <SpeakerphoneIcon className='w-6 h-6 text-white' aria-hidden='true' />
            </span>
            <p className='ml-3 font-medium text-white truncate'>
              <span className='md:hidden'>Nieuw product - Data-as-a-service</span>
              <span className='hidden md:inline'>Nieuw! Vanaf nu ook data-as-a-service bij The Reporting Agency.</span>
            </p>
          </div>
          <div className='flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto'>
            <Link
              to='/app/faq'
              className='flex items-center justify-center px-4 py-2 text-sm font-medium text-yellow-600 bg-white border border-transparent rounded-md shadow-sm hover:bg-yellow-50'
            >
              Meer lezen
            </Link>
          </div>
          <div className='flex-shrink-0 order-2 sm:order-3 sm:ml-3'>
            <button
              type='button'
              className='flex p-2 -mr-1 rounded-md hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2'
              onClick={() => setIsVisible(false)}
            >
              <span className='sr-only'>Dismiss</span>
              <XIcon className='w-6 h-6 text-white' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { LogoutIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { menuItems, MenuType } from './menuItem';

interface Props {
  menuType: MenuType;
}

/**
 *
 * This component returns a menu. For desktop it also registers a function running on an interval.
 *
 * @param menuType Menu destination (desktop or mobile)
 * @returns Menu items in a list.
 */
export const MenuItems = ({ menuType }: Props) => {
  const location = useLocation();

  const menuLinkClassesInactive = classNames({
    'flex items-center px-2 py-2 font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white group': true,
    'text-sm': menuType === 'desktop',
    'text-base ': menuType === 'mobile',
  });

  const menuLinkClassesActive = classNames({
    'flex items-center px-2 py-2 font-medium rounded-md group bg-gray-900 text-yellow-500': true,
    'text-sm': menuType === 'desktop',
    'text-base ': menuType === 'mobile',
  });
  const menuIconClasses = classNames({
    'w-6 h-6': true,
    'text-sm mr-3': menuType === 'desktop',
    'text-base mr-4': menuType === 'mobile',
  });
  const navClassNames = classNames({
    'flex-1 px-2 py-4 space-y-1': true,
    'bg-gray-800': menuType === 'desktop',
  });

  return (
    <nav className={navClassNames} aria-label='Sidebar'>
      {menuItems.map((m, idx) => {
        console.log(m.link);
        console.log(location.pathname);
        return (
          <Link
            key={idx}
            to={m.link}
            className={location.pathname.includes(m.link) ? menuLinkClassesActive : menuLinkClassesInactive}
          >
            <m.icon className={menuIconClasses} />
            {m.title}
          </Link>
        );
      })}
      <Link to='/logout' className={`${menuLinkClassesInactive}`}>
        <LogoutIcon className={menuIconClasses} />
        Uitloggen
      </Link>
      ;
    </nav>
  );
};

import { ChartSquareBarIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { ReportOverviewCard } from '../reports/ReportOverviewCard';

export const Allreports = () => {
  const { t } = useTranslation();
  return (
    <div className='py-6 space-y-4'>
      <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8'>
        {/* <Breadcrumbs /> */}
        <h1 className='flex items-center justify-start space-x-2 text-3xl font-bold text-gray-900 uppercase'>
          <ChartSquareBarIcon className='w-8 h-8' />
          <span className='tracking-tight'>{t('Alle rapportages')}</span>
          <span className='flex-grow-0 w-20 h-1 bg-black'></span>
        </h1>
      </div>

      <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 md:px-8'>
        <ReportOverviewCard />
      </div>
    </div>
  );
};

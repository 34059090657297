import 'assets/styles/app.css';
import React from 'react';
import ReactDOM from 'react-dom';
//
import { Provider } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';

import App from './app/App';
import { store } from './app/store';
import userManager from './features/auth/userManager';
import * as serviceWorker from './serviceWorker';

// Preload icons and user before rendering app.
const preload = () => {
  userManager.clearStaleState();
  loadUser(store, userManager);
};

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <App />
        </OidcProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

preload();
render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

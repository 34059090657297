import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Allreports } from '../pages/AllReports';
import { Dashboard } from '../pages/Dashboard';
import { DataUploadPage } from '../pages/DataUploadPage';
import { FAQPage } from '../pages/FAQPage';
import { ReportPage } from '../pages/ReportPage';
import { MainLayout } from '../pages/layouts/MainLayout';

const AppRoutes: React.FC = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path=''>
          <Navigate to='/app/dashboard' />
        </Route>
        <Route path='dashboard'>
          <Dashboard />
        </Route>
        <Route path='data-delivery'>
          <DataUploadPage />
        </Route>
        <Route path='data-delivery/a4a6d35d-7a89-43cc-8856-81897dda3dfc'>
          <DataUploadPage />
        </Route>
        <Route path='reports'>
          <Allreports />
        </Route>
        <Route path='reports/a4a6d35d-7a89-43cc-8856-81897dda3dfc'>
          <ReportPage />
        </Route>
        <Route path='faq'>
          <FAQPage />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default AppRoutes;

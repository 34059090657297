import { FaqEntry } from './FaqEntry';

/* This example requires Tailwind CSS v2.0+ */
const faqs = [
  {
    question: 'Lorem ipsum dolor sit amet',
    answer:
      'Donec convallis dolor condimentum libero rutrum vehicula. Nam venenatis fringilla lacinia. Duis vulputate malesuada pulvinar.',
  },
  {
    question: 'Lorem ipsum dolor sit amet',
    answer:
      'Maecenas justo urna, facilisis in velit eget, vehicula commodo sem. Pellentesque cursus tortor ante, vel luctus neque gravida at.',
  },
  {
    question: 'Lorem ipsum dolor sit amet',
    answer:
      'Pellentesque ut placerat tellus, vel mollis mi. Mauris scelerisque ullamcorper dolor, egestas tincidunt lectus egestas vitae. ',
  },
  {
    question: 'Lorem ipsum dolor sit amet',
    answer:
      'Sed at nisi a velit gravida finibus in ut augue. Fusce vel vestibulum lorem, quis eleifend diam. Maecenas at fermentum erat. ',
  },

  // More questions...
];
export const FaqOverview = () => {
  return (
    <div className='mt-12 lg:mt-0 lg:col-span-2'>
      <dl className='space-y-12'>
        {faqs.map((faq) => (
          <FaqEntry key={faq.question} {...faq} />
        ))}
      </dl>
    </div>
  );
};

interface OidcConfig {
  aiKey: string;
  apiUrl: string;
  oidcClientId: string;
  oidcAuthority: string;
}

declare global {
  interface Window {
    oidc: OidcConfig;
  }
}

const Config: OidcConfig = window.oidc;
export default Config;

import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';

import userManager from './userManager';

/**
 * This component is responsible for providing callsbacks for the OIDC-client, so that WHEN the user
 * is succesfully authenticated, they will either be redirected to the page they came from or they'll see
 * an error page.
 * @returns Component
 */
const CallbackPage = () => {
  const navigate = useNavigate();

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={(e) => {
        console.log('User returned', e);
        const redirectUri = localStorage.getItem('redirect_uri');
        navigate(redirectUri ? decodeURIComponent(redirectUri) : '/error/missingRedirectUri', { replace: true });
      }}
      errorCallback={() => {
        navigate('/error/error_logging_in', { replace: true });
      }}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
};

export default CallbackPage;

import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { twClassNames } from 'utils/twClassNames';

import nlLogo from '../../assets/images/flags/020-netherlands.svg';
import usLogo from '../../assets/images/flags/039-united states.svg';

const userNavigation = [
  { name: 'Nederlands', href: '#', icon: nlLogo },
  { name: 'English', href: '#', icon: usLogo },
];
export const TopNavLanguageChooser = () => {
  return (
    <Menu as='div' className='relative ml-3'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className='flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600'>
              <span className='sr-only'>Open user menu</span>
              <img src={nlLogo} className='w-8 h-8' />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              static
              className='absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      className={twClassNames(
                        active ? 'bg-gray-100' : '',
                        'px-4 py-2 text-sm text-gray-700 flex items-center space-x-2'
                      )}
                    >
                      <img src={item.icon} className='w-6 h-6' />
                      <span>{item.name}</span>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

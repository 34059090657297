import { BellIcon } from '@heroicons/react/outline';

export const TopNavNotificationButton = () => {
  return (
    <button className='flex p-1 text-gray-400 bg-white ounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'>
      <span className='sr-only'>View notifications</span>

      <BellIcon className='inline-flex w-6 h-6' aria-hidden='true' />
      <span className='flex w-3 h-3 -ml-1'>
        <span className='absolute inline-flex w-2 h-2 bg-yellow-600 rounded-full opacity-75 animate-ping'></span>
        <span className='relative inline-flex w-2 h-2 bg-yellow-600 rounded-full'></span>
      </span>
    </button>
  );
};
